import '../css/Home.scss';
import ImageCompressor from '../Components/ImageCompressor';

const Home = () => {
  return (
    <div className='home'>
      <div className='side__container'></div>
      <div className='center__container'>
        <ImageCompressor />
      </div>
      <div className='side__container'></div>
    </div>
  );
};

export default Home;
