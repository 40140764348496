import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import JSZip from 'jszip';

import '../css/Home.scss';

const ImageCompressor = () => {
  const [percentage, setPercentage] = useState('30');
  const [selectedImages, setSelectedImages] = useState<
    { file: string | ArrayBuffer; name: string; size: number }[]
  >([]);
  const [compressedImages, setCompressedImages] = useState<
    { file: string | ArrayBuffer; name: string; size: number }[]
  >([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const Images = Array.from(files).map((file) => {
        const sizeInKB = file.size / 1024; // Convert bytes to kilobytes
        const imageUrl = URL.createObjectURL(file); // Create a URL for the file

        return {
          file: imageUrl, // Use the URL as the file reference
          name: file.name,
          size: sizeInKB,
        };
      });

      setSelectedImages(Images);
      setCompressedImages(Images);
    }
  };

  const handleClick = () => {
    document.getElementById('fileInput')?.click();
  };

  const compressImage = async () => {
    if (selectedImages.length === 0) return;

    const compressedImages = await Promise.all(
      selectedImages.map(async (image) => {
        const response = await fetch(image.file as string);
        const blob = await response.blob();

        // Convert the Blob back to a File object
        const file = new File([blob], 'compressed_image.jpg', {
          type: blob.type,
          lastModified: Date.now(),
        });

        const compressedFile = await imageCompression(file, {
          initialQuality: (100 - Number(percentage)) / 100,
          useWebWorker: true,
        });
        const compressedUrl = URL.createObjectURL(compressedFile);

        return {
          file: compressedUrl,
          name: image.name,
          size: compressedFile.size / 1024, // Convert to kilobytes
        };
      })
    );

    setCompressedImages(compressedImages);
  };

  const downloadCompressedImages = async () => {
    const zip = new JSZip();

    for (let i = 0; i < compressedImages.length; i++) {
      const response = await fetch(compressedImages[i].file as string);
      const blob = await response.blob();
      zip.file(compressedImages[i].name, blob);
    }

    const zipData = await zip.generateAsync({
      type: 'blob',
      streamFiles: true,
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(zipData);
    link.download = 'TinyPics.zip';
    link.click();
  };

  const handleSliderChange = (value: string) => {
    setPercentage(value);
  };

  return (
    <div className='compressor'>
      <h1>Welcome to TinyPics!</h1>
      <button onClick={handleClick}>Select an Image</button>
      <button onClick={compressImage}>Compress</button>
      <button onClick={downloadCompressedImages}>Download</button>
      <input
        type='file'
        id='fileInput'
        style={{ display: 'none' }}
        accept='image/*'
        multiple
        onChange={handleFileChange}
      />

      <div className='slider__container'>
        <h4>Reduce Image Quality by {percentage}%</h4>
        <input
          type='range'
          className='form-range'
          min='10'
          max='90'
          step='1'
          value={percentage}
          onChange={(e) => handleSliderChange(e.target.value)}
        />
      </div>

      <div className='img__container'>
        {compressedImages.map((image, index) => (
          <div key={index} className='wrapper'>
            {/* <h5>{image.name}</h5> */}
            <img src={image.file as string} alt='Selected' />
            <div className='size'>
              <span>{Math.floor(selectedImages[index].size)} KB</span>
              <span>
                {Math.floor(
                  (image.size / selectedImages[index].size - 1) * 100
                )}
                %
              </span>
              <span>{Math.floor(image.size)} KB</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageCompressor;
